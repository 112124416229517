import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Date, NameSurname, Status } from "./contactlistCol";


//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import axios from 'axios';
import "jspdf-autotable";


import Dropzone from 'react-dropzone-uploader';
import moment from "moment";

import {
  getRest_Product as onGetRestProduct,
  addRest_Product as onAddRestProduct,
  updateRest_Product as onUpdateRestProduct,
  deleteRest_Product as onDeleteRestProduct,
} from "store/contacts/actions";
import { isEmpty } from "lodash";


//redux
import { useSelector, useDispatch } from "react-redux";
import { Price } from "../Orders/contactlistCol";

const ContactsList = props => {
  const tableRef = useRef(null);

  //meta title
  document.title = "Restorant Ürünleri | BİTTİ GİTTİ ADMIN PANELI";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [contactEdit, setEditContact] = useState();
  const [getFileName, setFileName] = useState('');

  
  const [getHtmlDataLong, setHtmlDataLong] = useState();
 
  const [getFileSubMedia, setFileSubMedia] = useState([]);

  const [getSubMediaID, setSubMediaID] = useState();

  const [getFileSubMediaAdd, setFileSubMediaAdd] = useState([]);
  const [getIsActive, setIsActive] = useState(false);
  // validation

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: (contactEdit && contactEdit.Timeperiod) || "",

      startDate: (contactEdit && moment(contactEdit.Order_DateTime).format('YYYY-MM-DD HH:mm')) || "",
      finishDate: (contactEdit && moment(contactEdit.Order_DateTimeFinish).format('YYYY-MM-DD HH:mm')) || "",

 
      
    },
    validationSchema: Yup.object({


    }),
    onSubmit: values => {
      if (isEdit) {

        const updateUser = {
          ID: contactEdit.ID,
          Timeperiod: values.title,

          Products_ID:getCategoryIDSecond,
          Rest_ID: JSON.parse(localStorage.getItem("authUser")).UserTypeID == 3 ? JSON.parse(localStorage.getItem("authUser")).Rest[0].ID : getCategoryID, 
          Order_DateTime: moment(values.startDate).format('YYYY-MM-DDTHH:mm:ssZ'),
          Order_DateTimeFinish: moment(values.finishDate).format('YYYY-MM-DDTHH:mm:ssZ'),

        };
        dispatch(onUpdateRestProduct(updateUser));
        setIsEdit(false);
        validation.resetForm();
      } else {
        const newUser = {
          Timeperiod: values["title"],

          Products_ID:getCategoryIDSecond,
          Rest_ID: JSON.parse(localStorage.getItem("authUser")).UserTypeID == 3 ? JSON.parse(localStorage.getItem("authUser")).Rest[0].ID : getCategoryID,
          Order_DateTime: moment(values.startDate).format('YYYY-MM-DDTHH:mm:ssZ'),
          Order_DateTimeFinish: moment(values.finishDate).format('YYYY-MM-DDTHH:mm:ssZ'),
        };
        // save new user
        dispatch(onAddRestProduct(newUser));
        validation.resetForm();
      }
      toggle();
    },
  });

  const { users } = useSelector(state => ({
    users: state.contacts.restproduct,
  }));

  const [userList, setUserList] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalMedia, setModalMedia] = useState(false);

  const [isEdit, setIsEdit] = useState(false);


  const [getCategoryID, setCategoryID] = useState(null);
  const [getCategoryIDSecond, setCategoryIDSecond] = useState(null);

  const [getCategory, setCategory] = useState([]);
  const [getCategorySecond, setCategorySecond] = useState([]);

  const columns = useMemo(
    () => [
     

      {
        Header: "Foto",
        // accessor: "name",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => (
          <>
            {cellProps.Products != null ? !cellProps.Products.ImageCover ? (
              <div className="avatar-xs">
                {cellProps.Products.Products_Name != null ? <span className="avatar-title rounded-circle">
                  {cellProps.Products.Products_Name.charAt(0)}
                </span> : null}
              </div>
            ) : (
              <div>
                {cellProps.Products.MobileCheck == true ? 
                <img
                  className="rounded-circle avatar-xs"
                  src={'https://kampanya.stechomeyazilim.com/Uploads/' + cellProps.Products.ImageCover}
                  alt=""
                />:
                <img
                  className="rounded-circle avatar-xs"
                  src={'https://megasubdomain.stechomeyazilim.info/' + cellProps.Products.ImageCover}
                  alt=""
                />}
              </div>
            ) : null}
          </>
        ),
      },
     
      {
        Header: "Ürün Adı",
        accessor: "Products.Products_Name",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },
       
      {
        Header: "Fiyat",
        accessor: "Products.Price",
        filterable: true,
        Cell: cellProps => {
          return <Price {...cellProps} />;
        },
      },

      {
        Header: "Stok",
        accessor: "Products.Stock",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "Rest. Adı",
        accessor: "Rest.ResName",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "Aktif Mi?",
        accessor: "IsActive",
        filterable: true,
        Cell: cellProps => {
          return <Status {...cellProps} />;
        },
      },
    
      {
        Header: "Başlangıç",
        accessor: "Order_DateTime",
        filterable: true,
        Cell: cellProps => {
          return <Date {...cellProps} />;
        },
      },
     
  
      {
        Header: "Bitiş",
        accessor: "Order_DateTimeFinish",
        filterable: true,
        Cell: cellProps => {
          return <Date {...cellProps} />;
        },
      },
      {
        Header: "İşlemler",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">

        
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Düzenle
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  onClickDelete(userData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Sil
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  const _getAuthData = async () => {

    await axios.get(`https://bittigitti.stechomeyazilim.info:5151/getRestBitti/select/`)
      .then((res) => {
        setCategory(res.data)
      })

   
  }

  const _getAuthDataSecond = async () => {

    await axios.get(`https://bittigitti.stechomeyazilim.info:5151/getProductsBitti/select/`)
      .then((res) => {
        setCategorySecond(res.data)
      })

   
  }


  useEffect(() => {
    _getAuthData()
    _getAuthDataSecond()

    let getData = localStorage.getItem("authUser");

    if (users && !users.length) {
      if(JSON.parse(localStorage.getItem("authUser")).UserTypeID == 3){
      dispatch(onGetRestProduct(JSON.parse(getData).Rest[0].ID))
      setIsEdit(false);
      }else{
        dispatch(onGetRestProduct(null))
        setIsEdit(false);
      }
      
    }
  }, [dispatch, users]);

  useEffect(() => {
    setContact(users);
    // setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      //setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };

  const toggleMedia = () => {
    setModalMedia(!modalMedia);
  };

  const handleUserClick = (arg) => {
    const user = arg;

    setEditContact(user);

    setCategoryIDSecond(user.Products_ID)
    setCategoryID(user.Rest_ID)
  
  

    setIsEdit(true);


    toggle();
  };


  

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = users => {
    setContact(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    if (contact && contact.ID) {
      dispatch(onDeleteRestProduct(contact.ID));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setUserList("");
    setIsEdit(false);
    toggle();
  };

  const _setChoiceCategory = arg => {
    setCategoryID(arg.target.value)
  }

  const _setChoiceCategorySecond = arg => {
    setCategoryIDSecond(arg.target.value)
  }
  const Preview = ({ meta }) => {
    const { name, percent, status, previewUrl } = meta;
    return (
      <div className="preview-box">
        <img src={previewUrl} /> <span className="name">{name}</span> - <span className="status">{status == "done" ? "Başarıyla Yüklendi" : "Yükleniyor"}</span>{status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span>}
      </div>
    )
  }


  const getUploadParams = ({ file }) => {
    const body = new FormData();
    body.append('dataFile', file);
    return { url: 'https://megaadmin.stechomeyazilim.info:8080/uploadfile', body }
  }
  

  const handleChangeStatusBanner = ({ xhr }) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response)
          console.log("lnslkdf", result.file)
          setFileSubMediaAdd(oldArray => [{ fileName: result.file.filename, fileType: result.file.mimetype }, ...oldArray]);
        }
      }
    }
  }
  const handleChangeStatus = ({ xhr }) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response);
          setFileName(result.file.filename)

        }
      }
    }
  }

 

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="Restorant Ürünleri Listesi" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>


                  <TableContainer
                    columns={columns}
                    data={users}
                    ref={tableRef}
                    isGlobalFilter={true}
                    isAddRestProduct={true}
                    handleOrderClicks={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />

        

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Düzenle" : "Restorant ürünü Ekle"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>

                          {JSON.parse(localStorage.getItem("authUser")).UserTypeID == 3 ? null :

                          <div className="mb-3">
                              <Label className="form-label">Restorant Seçiniz</Label>
                              <Input
                                name="paymentStatus"
                                type="select"
                                className="form-select"
                                defaultValue={getCategoryID}
                                onChange={(text) => _setChoiceCategory(text)}
                                value={
                                  getCategoryID
                                }>
                               <option value="">Lütfen Seçim Yapınız</option>

                                {getCategory.map((item, index) => (
                                  <option value={item.ID} key={item.ID}>{item.ResName}</option>
                                ))}

                              </Input>
                              {validation.touched.wsText && validation.errors.wsText ? (
                                <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                              ) : null}
                            </div> }
                            
                            <div className="mb-3">
                              <Label className="form-label">Ürün Seçiniz</Label>
                              <Input
                                name="paymentStatus"
                                type="select"
                                className="form-select"
                                defaultValue={getCategoryIDSecond}
                                onChange={(text) => _setChoiceCategorySecond(text)}
                                value={
                                  getCategoryIDSecond
                                }>
                                {getCategorySecond.map((item, index) => (
                                  <option value={item.ID} key={item.ID}>{item.Products_Name}</option>
                                ))}

                              </Input>
                              {validation.touched.wsText && validation.errors.wsText ? (
                                <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                              ) : null}
                            </div>
                         
                     


                            <div className="mb-3">
                      <Label className="form-label">Baş. Tarih</Label>
                      <Input
                        name="startDate"
                        type="datetime-local"
                        // value={orderList.orderdate || ""}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.startDate || ""}
                        invalid={
                          validation.touched.startDate && validation.errors.startDate ? true : false
                        }
                      />
                      {validation.touched.startDate && validation.errors.startDate ? (
                        <FormFeedback type="invalid">{validation.errors.startDate}</FormFeedback>
                      ) : null}
                    </div>


                    <div className="mb-3">
                      <Label className="form-label">Bit. Tarih</Label>
                      <Input
                        name="finishDate"
                        type="datetime-local"
                        // value={orderList.orderdate || ""}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.finishDate || ""}
                        invalid={
                          validation.touched.finishDate && validation.errors.finishDate ? true : false
                        }
                      />
                      {validation.touched.finishDate && validation.errors.finishDate ? (
                        <FormFeedback type="invalid">{validation.errors.finishDate}</FormFeedback>
                      ) : null}
                    </div>

                         

                          </Col>
                        </Row>
                        <Row>



                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Kaydet
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
